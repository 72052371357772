<template>
  <div>
    <v-btn to="/negapp/properties" text color="success">
      <v-icon>mdi-arrow-left</v-icon>
      <span class="text-lowercase">back</span>
    </v-btn>
    <h1>Property Images</h1>
    <p>Property {{ id }}</p>
    <p>{{$store.state.selectedPropertyType}}</p>
  </div>
</template>

<script>
export default {
  props: ['id'],
  created() {
  
    this.$store.commit("EXPIRE_LOGIN");
    if(this.$store.state.user === ''){
      this.$router.push('/');
    }
  
    console.log(this.$store.state.selectedPropertyType)
  }

}
</script>

